import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BaseCardModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/cards/base-card";
import { TranslucidCardComponent } from "./translucid-card.component";

@NgModule({
	imports: [CommonModule, BaseCardModule],
	declarations: [TranslucidCardComponent],
	exports: [TranslucidCardComponent],
})
export class TranslucidCardModule {}
